<template>
  <div id="defaultId1" class="company_detail">
    <gui_card id="ge6845" class="uc1096 pb20" label="" :isShowLabel="false">
      <div>
        <h2 id="gf7b16" class="operations-head detail_title_20 margin-bottom20">
          <div>
        
            <span class="operations-headspan" v-if="activeIEports=='1'">
              Top 10 Imports Products
            </span>
            <span class="operations-headspan" v-if="activeIEports=='2'">
              Top 10 Export Products
            </span>
            <el-select style="width:  14.2857143rem;"  v-model="Importyear" placeholder="Please Select" @change="ImportYear">
            <el-option v-for="item in Yearoptions" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          </div>

          <div class="flex_between_box">
       
            <div class="perations-content-left margin-left20">
              <span :class="activeIEports == '1'
                ? 'perations-button perations-button-active'
                : 'perations-button'
                " @click="ceckIEports('1')"> Imports</span>
              <span @click="ceckIEports('2')" :class="activeIEports == '2'
                ? 'perations-button perations-button-active'
                : 'perations-button'
                ">Exports</span>
            </div>
          </div>
        </h2>
        
        <div class="flex_center_between_box">
          <div class="Products_lesft" style="height: auto; ">
            <div ref="SupplyChainEchart" style="width: 98%; height: 100%"></div>
          </div>
          <div class="table-biao table-biao-left" style="position: relative;">

            <el-table :data="ImporttableData" ref="table" empty-text="No Data" style="width: 100%"
              class="table_height_30 table_font_size_14 table_contentHeight_40">
              <el-table-column prop="date" label="Date" align="left" width="100"  show-overflow-tooltip>
                <template slot-scope="scope">
                  <span class="table-content">{{ scope.row.date }}</span>
                </template>
              </el-table-column>


              <el-table-column prop="hsCode" label="HS Code" align="left" width="120" show-overflow-tooltip>

                <template slot-scope="scope">
                  <span class="" style="color: #1290C9;cursor: pointer;">{{ scope.row.hsCode }}</span>
                </template>
              </el-table-column>

              <el-table-column prop="products" label="Products" align="center"  show-overflow-tooltip>
                <template slot-scope="scope">
                    <span  style="color: #1290C9;cursor: pointer;">{{ scope.row.products }}</span>
                    <!-- </el-tooltip> -->
                </template>
              </el-table-column>
     
              <el-table-column prop="destinationCountry" label="Destination Country" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span style="color: #1290C9;cursor: pointer;">{{ scope.row.destinationCountry}}</span>
                    <!-- </el-tooltip> -->
                </template>
              </el-table-column>
              <el-table-column prop="amount" label="Amount"  align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span  >{{ scope.row.amount}}</span>
                    <!-- </el-tooltip> -->
                </template>
              </el-table-column>
            </el-table>

          </div>
        </div>

      </div>
    </gui_card>
    <gui_card id="ge6845" class="uc1096 pb20" label="" :isShowLabel="false" style="margin-top: 10px;">
      <div>
        <h2 class="operations-head flex_center_between_box">
          <span class="head-titel detail_title_20">Top 10 Imported Products</span>
          <el-select style="width:  14.2857143rem;"  v-model="Exportyear" placeholder="Please Select" @change="ExportYear">
            <el-option v-for="item in Yearoptions" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </h2>
        <div class="flex_center_between_box">
          <div class="Products_lesft" style="height: auto; ">
            <div ref="SupplyChainEchart1" style="width: 98%; height: 100%"></div>
          </div>
          <div class="table-biao" style="position: relative;">

            <el-table :data="ExportTableData" ref="table" empty-text="No Data" style="width: 100%"
              class="table_height_30 table_font_size_14 table_contentHeight_40">
              <el-table-column prop="impYear" label="Date" align="left" width="120" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span class="table-content">{{ scope.row.impYear }}</span>
                </template>
              </el-table-column>


              <el-table-column prop="hsCode" label="HS Code" align="left" width="120" show-overflow-tooltip>

                <template slot-scope="scope">
                  <span class="" style="color: #1290C9;cursor: pointer;">{{ scope.row.hsCode }}</span>
                </template>
              </el-table-column>

              <el-table-column prop="products" label="Products" align="center"  show-overflow-tooltip>>
                <template slot-scope="scope">
                  <div class="flex_center">
                    <span class="table-content" style="color: #1290C9;cursor: pointer;">{{ scope.row.products }}</span>
                    <!-- </el-tooltip> -->
                  </div>
                </template>
              </el-table-column>
       
              <el-table-column prop="countryNameOfPartner" label="Destination Country" align="center"  show-overflow-tooltip>
                <template slot-scope="scope">
             
                    <span style="color: #1290C9;cursor: pointer;">{{ scope.row.countryNameOfPartner}}</span>
           
                </template>
              </el-table-column>
              <el-table-column prop="amount" label="Amount"  align="center"  show-overflow-tooltip>
                <template slot-scope="scope">
                    <span >{{ scope.row.amount}}</span>
                    <!-- </el-tooltip> -->
                </template>
              </el-table-column>
            </el-table>

          </div>
        </div>

      </div>
    </gui_card>
    <ReLogin :reLoginPop="reLoginVis"></ReLogin>
  </div>

  <!--srcviewscompanydetailsBackgroundSnapshotvuehtmlEnd-->
</template>
<script>
// interfaceCode
//importStart
import {
  getNews,
  showImportAndExportProductsOfTop10,
} from "@/api/companyApi";
import rectcard from "@/components/rectCard/index.vue";
import content_text from "@/components/text_content/index.vue";
import gui_card from "@/components/gui_card/gui_card.vue";
import ReLogin from "@/components/ReLogin.vue";
//importEnd
import { mixins1 } from "@/mixins/index";
export default {
  name: "",

  props: {},
  mixins: [mixins1],
  components: {
    // gui_card start
    gui_card,
    // gui_card end

    rectcard,
    content_text,
    ReLogin,
  },
  computed: {},
  mounted() {

    this.getNewsImportList()

  },
  data() {
    return {
      reLoginVis: false,
      Importyear: '2024',
      Exportyear: '2024',
      Yearoptions: [
        {
          label: '2025',
          value: '2025',
        },
        {
          label: '2024',
          value: '2024',
        },
        {
          label: '2023',
          value: '2023',
        },
        {
          label: '2022',
          value: '2022',
        },
        {
          label: '2021',
          value: '2021',
        },
        {
          label: '2020',
          value: '2020',
        },
        {
          label: '2019',
          value: '2019',
        },
        {
          label: '2018',
          value: '2018',
        },
        {
          label: '2017',
          value: '2017',
        },
        {
          label: '2016',
          value: '2016',
        },
        {
          label: '2015',
          value: '2015',
        },
        {
          label: '2014',
          value: '2014',
        },
        {
          label: '2013',
          value: '2013',
        },

      ],
      page: {
        pageNo: 1,
        pageSize: 5,
        total: 0,
      },
      activeIEports: '1',
      activeIcon: 'computer1',
      keyNum: 0,
      SearchHscode: "",
      SelectHscode: '',

      ImporttableData: [],
      ExportTableData: [],


      staticDataImport: [

      ],
      staticDataExport: [

      ],

      newDialog: false,
      // srcviewscompanydetailsBackgroundSnapshotvueDataEnd
    };
  },
  methods: {
    ceckIEports(row) {
      this.activeIEports = row;
      this.getexportData();
    },
    CheckClick(value) {
      this.activeIcon = value
      if (value == 'computer1') {
        this.getexportData();

      }
    },
    ImportYear() {
      this.getNewsImportList()

    },
    ExportYear() {
      this.getNewsExportList()

    },
    ImportsuppleEchart() {

      this.staticDataImport.map(item => {
        let countryImg = this.getCountryImgUrl(item[3]);
        item[3] = require("../../../../../static" + countryImg)

      })
      const startYear = this.Importyear; // 选择特定年份
      const source = this.staticDataImport.slice(1).filter(function (d) {
        return d[1] === startYear; // 过滤出特定年份的数据
      });
      source.sort((a, b) => b[2] - a[2]);
      this.echartsBox = this.$echarts.init(this.$refs.SupplyChainEchart);
      this.echartsBox1 = this.$echarts.init(this.$refs.SupplyChainEchart1)
      const option = {
        grid: {
          top: '5%',
          bottom: 30,
          left: 10,
          right: 20
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            var tar = params[0];
            return tar.axisValueLabel + ' : ' + tar.value;
          }
        },
        xAxis: {
          type: 'value',
          position: 'bottom', // 将 xAxis 放置在底部
          offset: 10, // 向下移动 10
          axisLabel: {
            show: true,
            fontSize: 14,
            formatter: function (value) {

              return value.length > 10 ? value.substring(0, 10) + '...' : value; // 超过10个字符则省略
            },
          },
          axisTick: { show: false }
        },

        yAxis: {
          type: 'category',
          inverse: true,
          boundaryGap: false,
          axisLabel: {
            show: false,
            fontSize: 14,
            formatter: function (value) {

              return value.length > 10 ? value.substring(0, 10) + '...' : value; // 超过10个字符则省略
            },
          },
          data: source.map(item => item[0]), // 添加国家名称作为 x 轴数据
          // position: 'bottom',
          // offset: -10,
          axisLine: { show: false },
          axisTick: { show: false }
        },
        series: [
          {
            type: 'bar',
            barWidth: '25',
            showBackground: true,
            backgroundStyle: {
        color: 'rgba(180, 180, 180, 0.2)'
      },
            itemStyle: { color: '#009EA7' },
            emphasis: {
              itemStyle: { color: '#009EA7' }
            },
            label: {
        show: true,
              color: '#fff',
              z: 3,
         
              position: 'insideRight',
              //   formatter: '{c}%',
              formatter: function (params) {
                return (params['name'])
              }
      },
            data: source.map(item => item[2]) // 只保留值
          }
        ],

      
      };

      this.echartsBox.setOption(option);
      this.$nextTick(() => {
        this.echartsBox.resize();


      })
    },
    ExportsuppleEchart() {

      this.staticDataExport.map(item => {
        let countryImg = this.getCountryImgUrl(item[3]);
        item[3] = require("../../../../../static" + countryImg)

      })
      const startYear = this.Importyear; // 选择特定年份
      const source = this.staticDataExport.slice(1).filter(function (d) {
        return d[1] === startYear; // 过滤出特定年份的数据
      });
      source.sort((a, b) => b[2] - a[2]);
      this.echartsBox1 = this.$echarts.init(this.$refs.SupplyChainEchart1)

      const option1 = {
        grid: {
          top: '5%',
          bottom: 30,
          left: 10,
          right: 20
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            var tar = params[0];
            return tar.axisValueLabel + ' : ' + tar.value;
          }
        },
        xAxis: {
          type: 'value',
          position: 'bottom', // 将 xAxis 放置在底部
          offset: 10, // 向下移动 10
          axisLabel: {
            show: true,
            fontSize: 14,
            formatter: function (value) {

              return value.length > 10 ? value.substring(0, 10) + '...' : value; // 超过10个字符则省略
            },
          },
          axisTick: { show: false }
        },

        yAxis: {
          type: 'category',
          inverse: true,
          boundaryGap: false,
          axisLabel: {
            show: false,
            fontSize: 14,
            formatter: function (value) {

              return value.length > 10 ? value.substring(0, 10) + '...' : value; // 超过10个字符则省略
            },
          },
          data: source.map(item => item[0]), // 添加国家名称作为 x 轴数据
          // position: 'bottom',
          // offset: -10,
          axisLine: { show: false },
          axisTick: { show: false }
        },
        series: [
          {
            type: 'bar',
            barWidth: '25',
            showBackground: true,
            backgroundStyle: { color: '#F5F5F5' },
            itemStyle: { color: '#009EA7' },
            emphasis: {
              itemStyle: { color: '#009EA7' }
            },
            label: {
        show: true,
              color: '#fff',
              z: 3,
         
              position: 'insideRight',
              //   formatter: '{c}%',
              formatter: function (params) {
                return (params['name'])
              }
      },
            data: source.map(item => item[2]) // 只保留值
          }
        ],

   
      };
      this.echartsBox1.setOption(option1);
      this.$nextTick(() => {
        this.echartsBox1.resize();


      })
    },


    getNewsImportList() {
      showImportAndExportProductsOfTop10({
        country: 'US',
        startYear: '2022',
      }).then(res => {
        if (res.code == 200) {
          this.ImporttableData = res.data.importDetails
          this.ExportTableData= res.data.exportDetails
          res.data.importDetails.map(item=>{
            this.staticDataImport.push([
              item.products,
             '2024',
              item.amount,
              item.destinationCountry
            ])
          })
          res.data.exportDetails.map(item=>{
            this.staticDataExport.push([
              item.products,
             '2024',
              item.amount,
              item.destinationCountry
            ])
          })
          // this.staticDataImport = res.data
          this.staticDataImport.unshift(['Country', 'Year', 'Life Expectancy', 'CountryCode']),
          this.staticDataExport.unshift(['Country', 'Year', 'Life Expectancy', 'CountryCode']),
            this.ImportsuppleEchart()
            this.ExportsuppleEchart()
        }
      })
      // getfilterConditions()
    },


    ShowAll() {


    },
  },

};
</script>
<style scoped>
.uPdtqFl {
  padding-right: 2.857142857142857rem;
  padding-top: 2.142857142857143rem;
  padding-left: 2.857142857142857rem;
  padding-bottom: 2.142857142857143rem;
  box-shadow: 0rem 0.14285714285714285rem 1rem 0rem rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  border-top-left-radius: 0.2857142857142857rem;
  border-bottom-left-radius: 0.2857142857142857rem;
  border-top-right-radius: 0.2857142857142857rem;
  border-bottom-right-radius: 0.2857142857142857rem;
}

.importDetail-head {
  display: flex;
}

.head-titel {
  font-family: 'DMSans Bold';
  font-weight: 400;
  font-size: 2.142857142857143rem;
  display: flex;
  align-items: center;
}

.table-biao {
  height: auto;
  overflow-y: auto;
  background: #ffffff;
  border-radius: 0.2857142857142857rem;
  margin-top: 1.4285714285714286rem;
}

.operations-head {
  font-size: 2.142857142857143rem;
  /* font-family: "Arial Bold"; */
  font-family: 'DMSans Bold';
  color: #1a2332;
  text-align: left;
  font-style: normal;
  display: flex;
  justify-content: space-between;
}

.flex_center_between_box {
  display: flex;
  justify-content: space-between;
}

/deep/ .el-table .cell {
  font-size: 1.4285714285714286rem;
  color: #1a2332;
  font-family: 'DMSans Bold';
  padding-left: 1.4285714285714286rem !important;
}

/deep/ .highlight-cell {
  background-color: #f2f5f9 !important;
}

/deep/ .el-table th.el-table__cell {
  background-color: #f2f5f9 !important;
  font-family: 'DMSans Bold';
}

#cb02bf {
  padding-bottom: 1.4285714285714286rem;
}

.contclass {
  font-size: 1.4285714285714286rem;
  color: #1290c9;
  font-style: normal;
}

@media screen and (max-width: 1441px) {
  .contclass {
    font-size: 14px;

  }
}


.table_add {
  display: flex;
  justify-content: center;
  align-items: center;
}

.table_add_Text {
  font-family: 'DMSans Bold';
  text-align: left;
}

.table-content {
  font-size: 1.4285714285714286rem;
  color: #1a2332;
  display: inline-block;
  line-height: 2.142857142857143rem;
}

.uc1096 {
  height: 100%;
}

#cb02bf {
  padding-bottom: 1.4285714285714286rem;
}

.impact_status {
  width: 2.2857142857142856rem;
  height: 2.2857142857142856rem;
  text-align: center;
  border-radius: 50%;
}

.impact_status img {
  width: 1.1428571428571428rem;
  height: 1.1428571428571428rem;
  margin-top: 0.5rem;
}

.impact_status.green {
  background: #55b419;
}

.impact_status.hui {
  background: #ccc;
}

.impact_status.blue {
  background: #1290c9;
}

.impact_status.red {
  background: #ff7600;
}

.input-str {
  position: absolute;
  top: 0.2857142857142857rem;
  left: 13.214285714285714rem;
  z-index: 99;
  text-align: 7.142857142857143rem;
  width: 26%;
}

.input-str /deep/ .el-input__inner {
  margin-top: -0.14285714285714285rem;
}

/deep/.el-input__inner:focus {
  border: 0.07142857142857142rem solid #8497AB;
}

.uUIRzUi {
  padding: 2.142857142857143rem 2.857142857142857rem;
}

.Products_lesft {
  width: 43%;
  margin-right: 3rem;
}

.table-biao {
  width: 55%;
}
.table-biao-left /deep/ .el-table td.el-table__cell {
    border-right: none;
    height: 3.71rem;
}
</style>
